import React, { useState, useEffect } from 'react';
import { MapPin, Monitor, Headphones, Glasses, Apple, Home } from 'lucide-react';
import { Button } from './components/ui/button';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './components/ui/card';

const FeatureItem = ({ Icon, title, description }) => (
  <div className="flex items-start space-x-3 mb-4">
    <div className="flex-shrink-0">
      <Icon size={24} className="text-blue-500" />
    </div>
    <div>
      <h3 className="font-semibold">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  </div>
);

const CarInterior = () => {
  const [message, setMessage] = useState('');
  const [showMeeting, setShowMeeting] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [showMessage, setShowMessage] = useState(false);
  const [showHeadphoneMenu, setShowHeadphoneMenu] = useState(false);
  const [showSnackingMenu, setShowSnackingMenu] = useState(false);
  const [headphoneOption, setHeadphoneOption] = useState('');
  const [snackingOption, setSnackingOption] = useState('');
  const [sessionStarted, setSessionStarted] = useState(false);
  const [clock, setClock] = useState(0);
  const [audio, setAudio] = useState(null);
  const [showInitialMenu, setShowInitialMenu] = useState(true);
  const [showSessionControls, setShowSessionControls] = useState(false);
  const [showCompletionMessage, setShowCompletionMessage] = useState(false);
  const [showVRMenu, setShowVRMenu] = useState(false);
  const [vrSessionOption, setVRSessionOption] = useState('');
  const [showSnackingImage, setShowSnackingImage] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [showSnackMessage, setShowSnackMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (showMessage) {
      timer = setTimeout(() => {
        setShowMessage(false);
        if (message.includes('mirror')) {
          setShowMeeting(true);
          setCountdown(10);
        } else if (message.includes('headphones')) {
          setShowHeadphoneMenu(true);
        } else if (message.includes('VR')) {
          setShowVRMenu(true);
        } else if (message.includes('snack')) {
          setShowSnackingMenu(true);
        } else if (headphoneOption || vrSessionOption || snackingOption) {
          setShowSessionControls(true);
        }
      }, 3000);
    } else if (showMeeting && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0 && showMeeting) {
      setShowMeeting(false);
      setShowInitialMenu(true);
    } else if (showSnackMessage) {
      timer = setTimeout(() => {
        setShowSnackMessage(false);
        setShowCountdown(true);
        setCountdown(10);
      }, 3000);
    } else if (countdown > 0 && showCountdown) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0 && showCountdown) {
      setShowSnackingImage(false);
      setShowCountdown(false);
      setShowInitialMenu(true);
    } else if (showCompletionMessage) {
      timer = setTimeout(() => {
        setShowCompletionMessage(false);
        if (vrSessionOption) {
          setShowVRMenu(true);
        } else {
          setShowHeadphoneMenu(true);
        }
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [
    showMessage,
    showMeeting,
    countdown,
    showCountdown,
    showSnackMessage,
    message,
    headphoneOption,
    snackingOption,
    showCompletionMessage,
    vrSessionOption
  ]);

  useEffect(() => {
    let clockTimer;
    if (sessionStarted) {
      clockTimer = setInterval(() => setClock((prevClock) => prevClock + 1), 1000);
    }
    return () => clearInterval(clockTimer);
  }, [sessionStarted]);

  const handleButtonClick = (option) => {
    switch (option) {
      case 'mirror':
        setMessage('You are now ready to mirror your screen');
        setShowMessage(true);
        break;
      case 'headphones':
        setMessage('Your noise cancelling headphones are unlocked now');
        setShowMessage(true);
        break;
      case 'vr':
        setMessage('VR headset is unlocked for you');
        setShowMessage(true);
        break;
      case 'snacks':
        setMessage('The snack station is now ready for you');
        setShowMessage(true);
        break;
      default:
        setMessage('');
    }
  };

  const handleHeadphoneMenuClick = (option) => {
    setMessage(`You selected ${option}`);
    setHeadphoneOption(option);
    setShowHeadphoneMenu(false);
    setShowMessage(true);
  };

  const handleSnackingMenuClick = (option) => {
    setMessage(`You selected ${option}`);
    setSnackingOption(option);
    setShowSnackingMenu(false);
    setShowSnackMessage(true);
    setShowSnackingImage(true);
  };

  const handleVRMenuClick = (option) => {
    setMessage(`You selected ${option}`);
    setVRSessionOption(option);
    setShowVRMenu(false);
    setShowMessage(true);
    setHeadphoneOption(false)
  };

  const startSession = () => {
    console.log(headphoneOption || vrSessionOption)
    playAudio(headphoneOption || vrSessionOption);
    setSessionStarted(true);
  };

  const endSession = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
    setSessionStarted(false);
    setClock(0);
    setMessage(`You have completed the ${headphoneOption || vrSessionOption || snackingOption} session!`);
    setShowSessionControls(false);
    setShowCompletionMessage(true);
  };

  const playAudio = (option) => {
    let audioPath;
    switch (option) {
      case 'Practice mindfulness':
        audioPath = '/audio/mindfulness.mp3';
        break;
      case 'Just sleep':
        audioPath = '/audio/sleep.mp3';
        break;
      case 'Fun Karaoke':
        console.log("this is karaoke");
        audioPath = '/audio/karaoke.mp3';
        break;
      case 'Listen music':
        audioPath = '/audio/music.mp3';
        break;
      case 'Boxing workout':
        console.log("this is boxing");
        audioPath = '/audio/workout.mp3';
        break;
      case 'Swimming workout':
        audioPath = '/audio/workout.mp3';
        break;
      case 'Table tennis':
        audioPath = '/audio/workout.mp3';
        break;
      case 'Gym space':
        audioPath = '/audio/workout.mp3';
        break;
      default:
        return;
    }
    const newAudio = new Audio(audioPath);
    setAudio(newAudio);
    newAudio.play();
  };

  const handleHomeClick = () => {
    setShowInitialMenu(true);
    setShowMessage(false);
    setShowHeadphoneMenu(false);
    setShowSnackingMenu(false);
    setShowSnackMessage(false);
    setShowSessionControls(false);
    setShowMeeting(false);
    setSessionStarted(false);
    setClock(0);
    setShowVRMenu(false);
    setVRSessionOption('');
    setSnackingOption('');

    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  return (
    <div className="relative w-full h-full">
      <img src="/car-interior.jpg" alt="Car Interior" className="w-full h-full object-cover" />
      <div className="absolute top-[29%] left-[50.8%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg shadow-lg animated-background" style={{ width: '280px', height: '170px' }}>
        <div className="absolute top-2 right-2 cursor-pointer" onClick={handleHomeClick}>
          <Home className="text-blue-500" size={24} />
        </div>
        {showMessage ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-center text-sm text-blue-500">{message}</div>
          </div>
        ) : showSnackMessage ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-center text-sm text-blue-500">{message}</div>
          </div>
        ) : showMeeting ? (
          <div className="relative w-full h-full">
            <img
              src="/virtual-meeting.png"
              alt="Virtual Meeting"
              className="w-full h-full object-cover rounded-lg"
            />
            <div className="absolute top-2 right-2 text-white text-sm font-bold bg-black bg-opacity-50 p-2 rounded-lg">
              {countdown} seconds
            </div>
          </div>
        ) : showHeadphoneMenu ? (
          <div className="grid grid-cols-1 gap-0 w-full">
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleHeadphoneMenuClick('Practice mindfulness')}>
              Practice mindfulness
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleHeadphoneMenuClick('Just sleep')}>
              Just sleep
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleHeadphoneMenuClick('Fun Karaoke')}>
              Fun Karaoke
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleHeadphoneMenuClick('Listen music')}>
              Listen music
            </Button>
          </div>
        ) : showSnackingMenu ? (
          <div className="grid grid-cols-1 gap-0 w-full">
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleSnackingMenuClick('Fruit')}>
              Fruit
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleSnackingMenuClick('Dry Fruits')}>
              Dry Fruits
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleSnackingMenuClick('Protein Bar')}>
              Protein Bar
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleSnackingMenuClick('Baked chips')}>
              Baked chips
            </Button>
          </div>
        ) : sessionStarted ? (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="text-center text-sm text-blue-500">Session in progress</div>
            <div className="text-center text-sm text-blue-500">Time elapsed: {clock} seconds</div>
            <Button className="mt-4" onClick={endSession}>
              End Session
            </Button>
          </div>
        ) : showSessionControls ? (
          <div className="flex flex-col items-center justify-center h-full">
            <Button className="mb-4" onClick={startSession}>
              Start Session
            </Button>
            <Button onClick={endSession}>
              End Session
            </Button>
          </div>
        ) : showCompletionMessage ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-center text-sm text-blue-500">
              {message}
              <br />
              You have earned 10 points for this session.
            </div>
          </div>
        ) : showVRMenu ? (
          <div className="grid grid-cols-1 gap-0 w-full">
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleVRMenuClick('Boxing workout')}>
              Boxing workout
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleVRMenuClick('Swimming workout')}>
              Swimming workout
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleVRMenuClick('Table tennis')}>
              Table tennis
            </Button>
            <Button className="h-12 text-sm flex items-center justify-between px-4 -mb-2" onClick={() => handleVRMenuClick('Gym space')}>
              Gym space
            </Button>
          </div>
        ) : (
          showInitialMenu && (
            <div style={{ width: '250px', height: '160px', paddingTop: '10px' }}>
            <div className="grid grid-cols-2 gap-0">
              <Button className="h-10 text-xs flex items-start justify-start space-x-1" onClick={() => handleButtonClick('mirror')}>
                <Monitor />
                <span>Mirror - </span>
                <span>Free</span>
              </Button>
              <Button className="h-10 text-xs flex items-start justify-start space-x-1" onClick={() => handleButtonClick('headphones')}>
                <Headphones />
                <span>Headphones - </span>
                <span>$15</span>
              </Button>
              <Button className="h-10 text-xs flex items-start justify-start space-x-1" onClick={() => handleButtonClick('vr')}>
                <Glasses />
                <span>VR - </span>
                <span>$20</span>
              </Button>
              <Button className="h-10 text-xs flex items-start justify-start space-x-1" onClick={() => handleButtonClick('snacks')}>
                <Apple />
                <span>Snack - </span>
                <span>$5</span>
              </Button>
            </div>
            {message && <div className="mt-2 text-left text-xs text-blue-500">{message}</div>}
          </div>
          
          )
        )}
      </div>
      {showCountdown && (
        <div className="absolute top-[29%] left-[50.8%] transform -translate-x-1/2 -translate-y-1/2 text-sm text-white bg-black bg-opacity-50 p-2 rounded-lg">
          {countdown} seconds
        </div>
      )}
      {showSnackingImage && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-1/4">
          <img src="/images/snacking.png" alt="Snacking" className="w-full object-cover rounded-lg" />
        </div>
      )}
      {sessionStarted && (vrSessionOption || headphoneOption || snackingOption) && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-1/4">
          <img 
            src={
              vrSessionOption === 'Boxing workout'
                ? '/images/boxing_workout.png'
                : vrSessionOption === 'Swimming workout'
                ? '/images/swimming_workout.png'
                : vrSessionOption === 'Table tennis'
                ? '/images/table_tennis.png'
                : vrSessionOption === 'Gym space'
                ? '/images/gym_space.png'
                : headphoneOption === 'Practice mindfulness'
                ? '/images/practice_mindfulness.png'
                : headphoneOption === 'Just sleep'
                ? '/images/just_sleep.png'
                : headphoneOption === 'Fun Karaoke'
                ? '/images/fun_karaoke.png'
                : headphoneOption === 'Listen music'
                ? '/images/listen_music.png'
                : snackingOption === 'Fruit'
                ? '/images/fruit.png'
                : snackingOption === 'Dry Fruits'
                ? '/images/dry_fruits.png'
                : snackingOption === 'Protein Bar'
                ? '/images/protein_bar.png'
                : snackingOption === 'Baked chips'
                ? '/images/baked_chips.png'
                : ''
            }
            alt={vrSessionOption || headphoneOption || snackingOption}
            className="w-full object-cover rounded-lg"
          />
        </div>
      )}
    </div>
  );
};

function App() {
  const [step, setStep] = useState('form');
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(20);

  useEffect(() => {
    let timer;
    if (step === 'confirmed') {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        setStep('interior');
        clearInterval(timer);
      }, 20000);
    }
    return () => clearInterval(timer);
  }, [step]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (origin.trim() === '' || destination.trim() === '') {
      setError('Please enter both origin and destination.');
      return;
    }
    setError('');
    setStep('confirmed');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-screen bg-gray-100">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
      </div>
    );
  }

  if (step === 'interior') {
    return (
      <div className="relative w-full h-screen bg-gray-100 transition-opacity duration-500">
        <CarInterior />
      </div>
    );
  }

  if (step === 'confirmed') {
    return (
      <Card className="w-full max-w-2xl mx-auto transition-opacity duration-500">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Booking Confirmed!</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-center text-sm mb-6">
            Congratulations! Your ride is here. Have a productive and rejuvenating ride.
          </p>
          <p className="text-center text-sm font-semibold mb-4">Do you know that your ride is equipped with:</p>
          <FeatureItem
            Icon={Monitor}
            title="Big Screen"
            description="Mirror your laptop to attend meetings, respond to emails using just your voice, and much more."
          />
          <FeatureItem
            Icon={Headphones}
            title="Noise Cancelling Headphones"
            description="Immerse in mindfulness sessions, listen to music, and relax/sleep without being bothered by traffic noise."
          />
          <FeatureItem
            Icon={Glasses}
            title="VR Headset"
            description="Engage in interesting gamified workouts like boxing, swimming, etc."
          />
          <FeatureItem
            Icon={Apple}
            title="Healthy Snack Station"
            description="Nourish yourself on the go with a variety of healthy snacks."
          />
        </CardContent>
        <CardFooter>
          <p className="w-full text-center text-sm text-gray-500">
            The interior view will appear in {countdown} seconds...
          </p>
        </CardFooter>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center">Book a Cab</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            <MapPin className="text-gray-400" />
            <input
              type="text"
              placeholder="Enter origin"
              className="w-full p-2 border rounded"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            />
          </div>
          <div className="flex items-center space-x-2">
            <MapPin className="text-gray-400" />
            <input
              type="text"
              placeholder="Enter destination"
              className="w-full p-2 border rounded"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <Button type="submit" className="w-full">Book Now</Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default App;
